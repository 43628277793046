import Image from 'next/image'

import { PageMetaData } from '@/components/atomic/PageMetaData'
import { Header } from '@/components/styleguide/Header'
import { constants } from '@/lib/constants'
import { ROUTES } from '@/lib/routes'
import { InternalLink } from '@/components/styleguide/Link'
import { Size } from '@/types/system'
import { ButtonHierarchy } from '@/components/styleguide/Button'

export default function Custom404() {
  return (
    <>
      <PageMetaData
        title="Page Not Found | LeaseLoco"
        pageTitle="Page Not Found | LeaseLoco"
        description={`${constants.metaData.title} ${constants.metaData.tagLine}`}
        pageUrl={ROUTES.homePage.url}
      />
      <Header />
      <div className="error-boundary-page">
        <h2>404</h2>
        <p className="mt-4">The page you are looking for doesn&apos;t exist</p>
        <div className="mt-4">
          <InternalLink
            href={ROUTES.homePage.url}
            size={Size.Large}
            hierarchy={ButtonHierarchy.Filled}
            hasPadding
            hasBackground>
            Go To Homepage
          </InternalLink>
        </div>
        <div className="my-5">
          <Image
            src={`${constants.imageHost.assets}/mascot/full-body-3.png`}
            alt="Luis Loco"
            height="473"
            width="320"
          />
        </div>
      </div>
    </>
  )
}
